import React, { Component } from 'react';

import Img from 'gatsby-image/withIEPolyfill';

import CarouselStyles from './slider.module.css';


export class ImageCarousel extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      actualImage: 0
    };
  }
  render() {
    const images = this.props.images
    return (
      <div className={CarouselStyles.container}>
        <div className={CarouselStyles.mainImageContainer} >
          {images.map( ( image, index ) => (
            <div
              className={CarouselStyles.fullImage}
              active= {(index == this.state.actualImage).toString()}
            >
              <Img
                key={index}
                fluid={image.big}
              />
            </div>
          ) )}
        </div>
        {images && images.length > 1 &&
        <div className={CarouselStyles.bottomImagesContainer} >
          {images.map( ( image, index ) => (
            <div 
              active={(index == this.state.actualImage).toString()}
              onClick={() => this.setState({actualImage: index})}
            >
              <Img
                
                key={index}
                fixed={image.thumbnail}
              />
            </div>
          ) )}
         </div>
        }
      </div>
    )
  }
}


export default ImageCarousel;
