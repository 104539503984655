import React from "react"
import { graphql } from "gatsby"

import { withStoryGrid } from "components/storygrid"
import { withAllTags } from "components/collaboration/hooks/withAllTags"
import { FeaturedCollaboration } from "components/collaboration/featuredCollaboration"
import { Card } from "components/collaboration/card"
import { Content } from "components/collaboration/content"
import { TitleBanner } from "components/banners/titleBanner"

import Layout from "../components/layout"
import { BgPattern } from "components/backgrounds"
import BgStyle from "components/backgrounds/patterns.module.css"
import Style from "./archivecollaboration.module.css"

import { Link } from "components/link"

const CollaborationTypeMenu = ({ currentType, items }) => {
	return (
		<div className="flex flex-col md:flex-row w-full md:w-auto gap-4">
			{items?.map((c) => {
				return (
					<Link to={c.path} key={c.id}>
						{currentType.id === c.id ? (
							<button active="true" className="white-button w-full">
								{c.name}
							</button>
						) : (
							<button className="white-button w-full">{c.name}</button>
						)}
					</Link>
				)
			}) ?? ""}
		</div>
	)
}
export default (props) => {
	const { name, description } = props.pageContext
	const featuredCollaboration =
		props.data.allWordpressWpCollaborationType.nodes[0].featuredCollaboration
	const collaborations = props.data.allWordpressWpCollaboration.nodes
	let collaborationsFiltered = collaborations
	if (featuredCollaboration) {
		collaborationsFiltered = collaborations.filter(
			(element) => element.wordpress_id !== featuredCollaboration.wordpress_id
		)
	}
	const allTags = withAllTags()
	const Grid = withStoryGrid(Card, Content, collaborationsFiltered)

	// deal with internal category menu
	let cat_menu = []
	const currentType = props.data.allWordpressWpCollaborationType.nodes[0]
	const { parentType, childrenTypes, siblingTypes } = props.data
	if (siblingTypes?.nodes?.length && parentType) {
		cat_menu = [...siblingTypes.nodes]
	} else if (childrenTypes?.nodes?.length) {
		cat_menu = [currentType, ...childrenTypes.nodes].filter(
			(c) => c.wordpress_id
		)
		console.log(cat_menu)
	}
	return (
		<Layout context={props.pageContext} location={props.location}>
			<TitleBanner title={name} content={description} />
			<div className="entry">
				<div
					className="entry-content container px-5 mx-auto mt-20 relative"
					style={{ maxWidth: 1290 }}
				>
					<BgPattern className={`${BgStyle.pattern2} ${Style.bgTop}`} />

					{featuredCollaboration && (
						<FeaturedCollaboration
							title={featuredCollaboration.title}
							content={featuredCollaboration.content}
							images={featuredCollaboration.images}
						/>
					)}

					<div className="mb-8 w-full flex justify-center bg-transparent">
						{cat_menu.length > 0 && (
							<CollaborationTypeMenu
								currentType={
									props.data.allWordpressWpCollaborationType.nodes[0]
								}
								items={cat_menu}
							/>
						)}
					</div>
					<Grid />
					<BgPattern className={`${BgStyle.pattern1} ${Style.bgBottom}`} />
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query collaborationQuery($id: String!, $parentId: String) {
		allWordpressWpCollaboration(
			filter: { collaboration_type: { elemMatch: { id: { eq: $id } } } }
			sort: { order: ASC, fields: menu_order }
		) {
			nodes {
				title
				path
				content
				wordpress_id
				menu_order
				tags {
					id
					name
				}
				featured_media {
					localFile {
						childImageSharp {
							fluid(maxWidth: 370) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				images {
					localFile {
						...GalleryThumbnail
					}
				}
			}
		}
		allWordpressWpCollaborationType(
			filter: { id: { eq: $id }, count: { gt: 0 } }
		) {
			nodes {
				id
				name
				path
				featuredCollaboration {
					title
					content
					wordpress_id
					images {
						localFile {
							childImageSharp {
								big: fluid(maxHeight: 451) {
									...GatsbyImageSharpFluid
								}
								thumbnail: fixed(height: 80, width: 100) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
		childrenTypes: allWordpressWpCollaborationType(
			filter: { parent_element: { id: { eq: $id } }, count: { gt: 0 } }
		) {
			nodes {
				name
				count
				path
				id
				wordpress_id
			}
		}
		parentType: wordpressWpCollaborationType(id: { eq: $parentId }) {
			name
			path
			id
		}
		siblingTypes: allWordpressWpCollaborationType(
			filter: { parent_element: { id: { eq: $parentId } }, count: { gt: 0 } }
		) {
			nodes {
				name
				path
				id
			}
		}
	}
`
