import React from 'react'
import ContentCard from 'components/cards'
import ContentStyles from './content.module.css'
import {SimpleGallery} from 'components/gallery'
export const Content = (props) => {
  let images = []
  if(props.images && props.images.length ) {
    props.images.forEach(image => {
      if(image.localFile && image.localFile.thumbnail){
        images.push(image.localFile.thumbnail)
      }
    });
  }
  return (
    <>
      <div className={ContentStyles.container} dangerouslySetInnerHTML={{__html: props.content}}/>
      <footer className={`entry-footer ${ContentStyles.footer}`}>
        {images.length > 0 &&
         <div className="mx-auto">
           <SimpleGallery images={images}/>
         </div>
        }
      </footer>
    </>
  )
}
  
