import React from 'react'

import CollaborationSlider from './slider'

import FeaturedStyles from './featuredStyles.module.css'

export const FeaturedCollaboration = ({images, title, content}) =>{
  return (
  <div className={FeaturedStyles.container}>
  
    {images && images.length > 0  && 
     <CollaborationSlider images={images.map((image) => image.localFile.childImageSharp)} />
    }
    <div className={FeaturedStyles.content}>
      <h2 className={FeaturedStyles.title} dangerouslySetInnerHTML={{__html: title}} />
      <div dangerouslySetInnerHTML={{__html: content}} />
    </div>
  </div>
)
}
